export const originsConfig = {
  opentix: { label: 'OPENTIX', value: 'opentix' },
  pospal: { label: 'PosPal', value: 'pospal' },
  weiby: { label: 'Weiby', value: 'weiby' },
  drfoot: { label: 'DrFoot', value: 'drfoot' },
  dudoo: { label: 'Dudoo', value: 'dudoo' },
}

export const salesItemFieldConfig = {
  opentix: {
    name: { label: '場次名稱' },
    eventTimeRange: { label: '場次起迄時間' },
  },
}

export const memberExternalTransactionStatusConfig = {
  pending: { label: '訂單處理中', value: 'pending', type: 'info' },
  created: { label: '訂單完成', value: 'created', type: 'action' },
  invalid: { label: '訂單取消', value: 'invalid', type: 'danger' },
}
